import './App.css';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import AddPaymentMethodFrame from './pages/add-payment-method-frame/AddPaymentMethodFrame';
import AddPaymentMethod from './pages/add-payment-method/AddPaymentMethod';
import CardDetailsFrame from './pages/card-details-frame/CardDetailsFrame';
import CardDetails from './pages/card-details/CardDetails';
import CardListFrame from './pages/card-list-frame/CardListFrame';
import CardList from './pages/card-list/CardList';
import CreatePaymentFrame from './pages/create-payment-frame/CreatePaymentFrame';
import CreatePayment from './pages/create-payment/CreatePayment';
import EditPaymentFrame from './pages/edit-payment-method-frame/EditPaymentMethodFrame';
import EditPayment from './pages/edit-payment-method/EditPaymentMethod';
import theme from './theme/theme';
import PaymentDetails from './pages/payment-details/PaymentDetails';
import PaymentDetailsFrame from './pages/payment-details-frame/PaymentDetailsFrame';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route exact path="/create-payment">
            <CreatePayment orderAmount={299} />
          </Route>
          <Route exact path="/create-payment-frame">
            <CreatePaymentFrame />
          </Route>
          <Route exact path="/card-list">
            <CardList selectCard={() => {}} addCard={() => {}} />
          </Route>
          <Route exact path="/card-list-frame">
            <CardListFrame />
          </Route>
          <Route exact path="/card-details">
            <CardDetails
              cardId={'123'}
              onDeleteSuccess={() => {}}
              onDeleteFailure={() => {}}
              editCard={() => {}}
            />
          </Route>
          <Route exact path="/card-details-frame">
            <CardDetailsFrame />
          </Route>
          <Route exact path="/edit-payment">
            <EditPayment
              cardId={'123'}
              onSuccess={() => {}}
              onFailure={() => {}}
            />
          </Route>
          <Route exact path="/edit-payment-frame">
            <EditPaymentFrame />
          </Route>
          <Route exact path="/add-payment-method">
            <AddPaymentMethod
              onSuccess={() => {}}
              onFailure={() => {}}
              clientId="123"
            />
          </Route>
          <Route exact path="/add-payment-method-frame">
            <AddPaymentMethodFrame />
          </Route>
          <Route exact path="/payment-details">
            <PaymentDetails transactionId={'123'} />
          </Route>
          <Route exact path="/payment-details-frame">
            <PaymentDetailsFrame />
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
