import React from 'react';
import EditPayment from '../edit-payment-method/EditPaymentMethod';

const EditPaymentFrame: React.FC = () => {
  const onInit = () => {
    window.xprops?.onInitHook();
  };

  const onSuccess = (cardId: string) => {
    window.xprops?.onEditCardSuccessHook(cardId);
  };

  const onFailure = (cardId: string) => {
    window.xprops?.onEditCardFailureHook &&
      window.xprops?.onEditCardFailureHook(cardId);
  };

  const onResize = (width: number, height: number) => {
    window.xprops?.onResizeHook && window.xprops?.onResizeHook(width, height);
  };

  return (
    <EditPayment
      cardId={window.xprops?.cardId}
      onSuccess={onSuccess}
      onFailure={onFailure}
      onInit={onInit}
      onResize={onResize}
    />
  );
};

export default EditPaymentFrame;
