import * as Square from '@square/web-sdk';
import React, { useEffect, useState, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

import { getCustomerCards } from '../../api/card.api';
import { createCardPayment } from '../../api/payment.api';
import Button from '../../components/Button/Button';
import CardListItem from '../../components/CardListItem/CardListItem';
import ErrorBanner from '../../components/ErrorBanner/ErrorBanner';
import { flagIfExpired } from '../../components/ExpireService/ExpireService';
import RadioSelectForm from '../../components/RadioSelectForm/RadioSelectForm';
import SquareCardInput from '../../components/SquareCardInput/SquareCardInput';
import Typography from '../../components/Typography/Typography';
import {
  CARD_LIST_ERRORS,
  CREATE_PAYMENT_ERRORS,
} from '../../constants/ConstantData';
import { ButtonKind, TypographyStyle } from '../../theme/theme';
import { Card } from '../../types/CardTypes';

export interface ICreatePaymentProps {
  orderAmount: number;
  onInit?: (isSuccess: boolean) => void;
  onSuccess?: (
    transactionId: string,
    cardBrand: string,
    lastFour: string
  ) => void;
  onResize?: (width: number, height: number) => void;
  onFailure?: (message: string, referenceId?: string) => void;
}

const DivStyled = styled.div`
  padding-top: 16px;
`;

const ButtonStyled = styled(Button)`
  margin-top: 12px;
`;

const TypographyStyled = styled(Typography)`
  display: block;
`;

const LoadingDivStyled = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1em;
`;

const Loader = () => (
  <LoadingDivStyled data-testid={'create-payment-loader'}>
    <Skeleton circle={true} height={12} width={12} />
    <Skeleton height={24} style={{ width: '70vw' }} />
  </LoadingDivStyled>
);

const LoadingComponent = (
  <>
    <Loader />
    <Loader />
    <Loader />
  </>
);

const CreatePayment: React.FC<ICreatePaymentProps> = (
  props: ICreatePaymentProps
) => {
  const [squareCardFrame, setSquareCardFrame] = useState<
    Square.Card | undefined
  >(undefined);
  const targetRef = useRef<HTMLDivElement>(null);
  const [saveNewCardState, setSaveNewCardState] = useState<boolean>(false);
  const [defautCardState, setDefautCardState] = useState<boolean>(false);
  const [cardSelection, setCardSelection] = useState<string>('');
  const [cardList, setCardList] = useState<Card[] | undefined>();
  const [showError, setShowError] = useState<boolean>(false);
  const [errorTitle, setErrorTitle] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [defaultCard, setDefaultCard] = useState<string>('');
  const [squareFrameError, setSquareFrameError] = useState<boolean>(false);
  const [disableCheckout, setDisableCheckout] = useState<boolean>(false);

  useEffect(() => {
    fetchCustomerCards();
  }, []);

  useEffect(() => {
    setShowError(false);
  }, [cardSelection]);

  useEffect(() => {
    if (targetRef.current) {
      props.onResize &&
        props.onResize(
          targetRef.current.offsetWidth,
          targetRef.current.offsetHeight
        );
    }
  }, [squareCardFrame, cardSelection, cardList, showError, squareFrameError]);

  async function fetchCustomerCards() {
    try {
      const cards = await getCustomerCards();
      autoSetDefaultCard(cards);
      setCardList(cards);
    } catch (error: any) {
      handleError(CARD_LIST_ERRORS.TITLE, error);
    }
  }

  const autoSetDefaultCard = (cardList: Card[]) => {
    if (cardList && cardList.length > 0) {
      const defaultCardInList = cardList.find((card: Card) => card.defaultCard);
      if (typeof defaultCardInList !== 'undefined') {
        if (!flagIfExpired(defaultCardInList?.expDate)) {
          setDefaultCard(defaultCardInList.id);
          setCardSelection(defaultCardInList.id);
        }
      }
    }
  };

  async function handlePayment(cardToken?: string) {
    const paymentResponse = await createCardPayment({
      cardNonce: cardSelection === 'newCard' ? cardToken : null,
      cardId: cardSelection === 'newCard' ? null : cardSelection,
      amount: props.orderAmount,
      saveCard: saveNewCardState,
      defaultCard: defautCardState,
    });

    if (props.onSuccess) {
      const {
        payment: {
          id,
          card: { cardBrand, lastFour },
        },
      } = paymentResponse;
      props.onSuccess(id, cardBrand, lastFour);
    }
  }

  function handleError(title: string, error: any) {
    setDisableCheckout(false);
    setErrorTitle(title);
    setErrorMessage(
      CREATE_PAYMENT_ERRORS.MSG[error.code] || CREATE_PAYMENT_ERRORS.MSG.ERROR
    );
    setShowError(true);
    props.onFailure && props.onFailure(error.message);
  }

  async function handleOrderSubmit() {
    showError && setShowError(false);
    if ((cardSelection === 'newCard' || !cardSelection) && squareCardFrame) {
      setDisableCheckout(true);
      try {
        const result = await squareCardFrame.tokenize();
        if (result.status === 'OK') {
          handlePayment(result.token).catch((error: any) =>
            handleError(CREATE_PAYMENT_ERRORS.TITLE, error)
          );
        } else {
          setDisableCheckout(false);
        }
      } catch (error: any) {
        handleError(CREATE_PAYMENT_ERRORS.TITLE, error);
      }
    } else if (cardSelection) {
      setDisableCheckout(true);
      handlePayment().catch((error: any) => {
        handleError(CREATE_PAYMENT_ERRORS.TITLE, error);
      });
    }
  }

  function isCheckoutDisabled(): boolean {
    return (
      (cardList?.length && !defaultCard && !cardSelection) ||
      (!cardList?.length && squareFrameError)
    );
  }

  return (
    <div id="payment-form checkbox-input" ref={targetRef}>
      {showError && <ErrorBanner title={errorTitle} message={errorMessage} />}

      {!cardList && !showError && LoadingComponent}

      {cardList && (
        <>
          <RadioSelectForm
            groupName="create-payment"
            selected={cardSelection}
            setSelected={(value: any) => setCardSelection(value.key)}
            noCardsOnFile={!cardList?.length}
            defaultCardId={defaultCard}
          >
            {cardList &&
              cardList.map((card: Card) => (
                <CardListItem
                  key={card.id}
                  lastFour={card.lastFour}
                  cardType={card.cardBrand}
                  isDefault={card.defaultCard}
                  expDate={card.expDate}
                ></CardListItem>
              ))}

            <div key="newCard">
              <TypographyStyled
                typographyStyle={TypographyStyle.MinorBold}
                label="New Credit or Debit Card"
              />
              {(cardSelection === 'newCard' || !cardList?.length) && (
                <DivStyled>
                  <SquareCardInput
                    setCard={setSquareCardFrame}
                    saveVisible={true}
                    saveChecked={saveNewCardState}
                    saveOnClick={setSaveNewCardState}
                    defaultVisible={true}
                    defaultChecked={defautCardState}
                    defaultOnClick={setDefautCardState}
                    setSquareIFrame={setSquareFrameError}
                  />
                </DivStyled>
              )}
            </div>
          </RadioSelectForm>

          <ButtonStyled
            kind={ButtonKind.Primary}
            id="card-button"
            onClick={handleOrderSubmit}
            label="Checkout"
            disabled={isCheckoutDisabled() || disableCheckout}
          />
        </>
      )}
    </div>
  );
};

export default CreatePayment;
