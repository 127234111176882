import { ConfiguredMiddleware, WretcherOptions } from 'wretch';

export function authHeader() {
  const authToken =
    window.xprops?.authToken || process.env.REACT_APP_API_AUTH_TOKEN;
  return authToken;
}

export function getLogTraceId() {
  return window.xprops?.traceId || '';
}

export const authMiddleware: ConfiguredMiddleware =
  (next) => (url: string, opts: WretcherOptions) => {
    opts.headers = { ...opts.headers, Authorization: `Bearer ${authHeader()}` };
    return new Promise((res) => res(next(url, opts)));
  };

export const authCatcherMiddleware: ConfiguredMiddleware =
  (next) => async (url: string, opts: WretcherOptions) => {
    const response = await next(url, opts);
    if (response.status === 401) {
      window.xprops && window.xprops.onAuthError();
      return Promise.reject(response);
    }
    return Promise.resolve(response);
  };

export const logTracerMiddleware: ConfiguredMiddleware =
  (next) => (url: string, opts: WretcherOptions) => {
    opts.headers = { ...opts.headers, 'X-Correlation-ID': getLogTraceId() };
    return new Promise((res) => res(next(url, opts)));
  };
