import React from 'react';
import styled from 'styled-components';

import { TypographyStyle } from '../../theme/theme';
import { CardBrand } from '../../types/CardTypes';
import { convertUnderscorestoSpaces } from '../../utils/utils';
import PaymentIcon from '../PaymentIcon/PaymentIcon';
import Typography from '../Typography/Typography';

const DivStyled = styled.div`
  border-radius: 4px;
  display: flex;
  gap: 20px;
`;

const CardListDivStyled = styled.div`
  opacity: 80;
  display: flex;
  vertical-align: middle;
  justify-content: center;
  flex-direction: column;
`;

const StyledCardIcon = styled(PaymentIcon)`
  font-size: 24px;
  width: 36px;
  height: 24px;
`;

export interface ICardItemProps {
  lastFour: string;
  cardType: CardBrand;
  pageName?: string;
}

function CardItem(props: ICardItemProps) {
  return (
    <DivStyled>
      <StyledCardIcon cardBrand={props.cardType} />
      <CardListDivStyled {...props}>
        <Typography
          typographyStyle={
            props.pageName === 'cardDetails'
              ? TypographyStyle.MinorNormal
              : TypographyStyle.MinorBold
          }
          label={convertUnderscorestoSpaces(
            props.cardType + ' ···· ' + props.lastFour
          )}
          {...props}
        />
      </CardListDivStyled>
    </DivStyled>
  );
}

export default CardItem;
