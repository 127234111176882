import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

const DivStyled = styled.div`
  display: flex;
  flex-direction: row;
  margin: 5px 0px;
  gap: 20px;
`;

const CardSkeleton = () => {
  return (
    <DivStyled data-testid="card-skeleton">
      <Skeleton height={24} width={27} />
      <Skeleton height={24} width={110} />
    </DivStyled>
  );
};

export default CardSkeleton;
