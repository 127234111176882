import React from 'react';
import CreatePayment from '../create-payment/CreatePayment';

const CreatePaymentFrame: React.FC = () => {
  const onInit = () => {
    window.xprops?.onInitHook();
  };

  const onSuccess = (
    transactionId: string,
    cardBrand: string,
    lastFour: string
  ) => {
    window.xprops?.onPaymentSuccessHook(transactionId, cardBrand, lastFour);
  };

  const onFailure = (message: string, referenceId?: string) => {
    window.xprops?.onPaymentFailureHook &&
      window.xprops?.onPaymentFailureHook(message, referenceId);
  };

  const onResize = (width: number, height: number) => {
    window.xprops?.onResizeHook && window.xprops?.onResizeHook(width, height);
  };

  return (
    <CreatePayment
      orderAmount={window.xprops?.orderAmount}
      onInit={onInit}
      onSuccess={onSuccess}
      onFailure={onFailure}
      onResize={onResize}
    />
  );
};

export default CreatePaymentFrame;
