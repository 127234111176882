import React from 'react';
import styled from 'styled-components';

import { TypographyStyle } from '../../theme/theme';
import Typography from '../Typography/Typography';

export interface ILabelValueProps {
  label: string;
  value: string | null;
}

const ContainerDiv = styled.div`
  display: flex;
  vertical-align: middle;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 0.3rem;
  padding-top: 0.3rem;
`;

const LabelTypography = styled(Typography)`
  font-weight: bold;
`;

function LabelValue({ label, value }: ILabelValueProps) {
  return (
    <ContainerDiv>
      <LabelTypography
        typographyStyle={TypographyStyle.MinorNormal}
        label={label}
      />
      <Typography typographyStyle={TypographyStyle.MinorNormal} label={value} />
    </ContainerDiv>
  );
}

export default LabelValue;
