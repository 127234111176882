import React from 'react';
import styled from 'styled-components';
import { ButtonKind, TypographyStyle } from '../../theme/theme';
import Typography from '../Typography/Typography';

export interface IButtonProps {
  kind: ButtonKind;
  label: string;
  disabled?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  [x: string]: any;
}

const ButtonStyled = styled.button<IButtonProps>`
  background: ${(props) =>
    props.kind === ButtonKind.Primary
      ? props.theme.color.primary
      : props.theme.color.onPrimary};
  color: ${(props) =>
    props.kind === ButtonKind.Primary
      ? props.theme.color.onPrimary
      : props.theme.color.primary};
  height: 3.5em;
  cursor: pointer;
  padding: 0.25em 1em;
  border: ${(props) => (props.kind === ButtonKind.Tertiary ? '0px' : '2px')}
    solid ${(props) => props.theme.color.primary};
  border-radius: 4px;
  width: 100%;
  font-weight: ${(props) =>
    props.kind === ButtonKind.Secondary ? '630' : '500'};

  &:disabled {
    background: ${(props) => props.theme.color.lightgray};
    color: ${(props) => props.theme.color.gray4};
    border: none;
    font-weight: 700;
  }
`;

const TypographyStyled = styled(Typography)`
  opacity: 100%;
`;

const Button: React.FC<IButtonProps> = ({
  label,
  disabled,
  onClick,
  ...props
}) => {
  return (
    <ButtonStyled
      type="button"
      onClick={onClick}
      {...props}
      disabled={disabled}
    >
      <TypographyStyled
        typographyStyle={TypographyStyle.MajorNormal}
        label={label}
      />
    </ButtonStyled>
  );
};

export default Button;
