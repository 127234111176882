import React, {
  useEffect,
  useState,
  useReducer,
  useCallback,
  useRef,
} from 'react';
import Modal from '../Modal/Modal';
import {
  AccertifyCreateCardDto,
  createPaymentMethod,
} from '../../api/card.api';
import { PAYMENT_FORM_DATA } from '../../constants/ConstantData';
import TextInput from '../Input/TextInput';
import DropdownInput from '../Input/DropdownInput';

import {
  ButtonStyled,
  CentreDiv,
  DivBodyStyled,
  DivStyled,
  DivWidth,
  ErrorDiv,
  FormBodyStyled,
  LabelStyled,
  Overlay,
  SelectStyledDate,
} from './AccertifyStyles';
import { getExpiryMonths, getExpiryYears } from '../../utils/utils';
import Loader from '../Loader/Loader';

export interface IPaymentFormProps {
  url: string;
  states: any[];
  onSuccess: (cardDetails: any) => void;
}

export interface IFormProps {
  showInfoModal: boolean;
}

interface ICard {
  number: string;
  securityCode: string;
  expiryMonth: string;
  expiryYear: string;
  nameOnCard: string;
}

interface IFields {
  card: ICard;
}

enum FormActionKind {
  FORM_INPUT_UPDATE = 'FORM_INPUT_UPDATE',
}

interface IFormAction {
  type: FormActionKind;
  value: string;
  isValid: boolean;
  input: string;
}

interface IFormState {
  isFormValid: boolean;
  inputValues: any;
  inputValidities: any;
}

let inputValues = {};
let inputValidities = {};

PAYMENT_FORM_DATA.PaymentFormParameter.map((item) => {
  if (!item.readOnly) {
    inputValues[item.identifier] = '';
    if (item.required) inputValidities[item.identifier] = false;
    if (!item.required) inputValidities[item.identifier] = true;
  }
});

const formReducer = (state: IFormState, action: IFormAction): IFormState => {
  switch (action.type) {
    case FormActionKind.FORM_INPUT_UPDATE:
      const updatedInputValues = {
        ...state.inputValues,
        [action.input]: action.value,
      };
      const updatedInputValidities = {
        ...state.inputValidities,
        [action.input]: action.isValid,
      };
      let updateIsFormValid = true;

      for (const key in updatedInputValidities) {
        updateIsFormValid = updateIsFormValid && updatedInputValidities[key];
      }
      return {
        isFormValid: updateIsFormValid,
        inputValues: updatedInputValues,
        inputValidities: updatedInputValidities,
      };

    default:
      return state;
  }
};

const PaymentForm: React.FC<IPaymentFormProps> = (props: IPaymentFormProps) => {
  const [formState, dispatchFormState] = useReducer(formReducer, {
    inputValues: inputValues,
    inputValidities: inputValidities,
    isFormValid: false,
  });

  const [externalErrorHandling, setExternalErrorhandling] = useState({
    card_number: '',
    cardholder_name: '',
    security_code: '',
  });

  const sortedStates = props.states.sort((a, b) => {
    if (a.code_display < b.code_display) {
      return -1;
    }
    if (a.code_display > b.code_display) {
      return 1;
    }
    return 0;
  });

  const formValues = useRef({});
  //Expiry Year
  const [showExpYearError, setShowExpYearError] = useState<boolean>(false);
  const [expYearError, setExpYearError] = useState('');
  //Expiry Month
  const [showExpMonthError, setShowExpMonthError] = useState<boolean>(false);
  const [expMonthError, setExpMonthError] = useState('');
  const [commonErrors, setCommonErrors] = useState('');
  const [showCommonErrors, setShowCommonErrors] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [modalHeader, setModalHeader] = useState<string>('');
  const [showLoader, setShowLoader] = useState(false);

  // ATTACH HOSTED FIELDS TO YOUR PAYMENT PAGE FOR A CREDIT CARD
  const fields: IFields = {
    card: {
      number: '#card_number',
      securityCode: '#security_code',
      expiryMonth: '#expiry_month',
      expiryYear: '#expiry_year',
      nameOnCard: '#cardholder_name',
    },
  };

  async function completeTransaction(
    sessionId: string,
    formParameter: object,
    nameOnCard: string
  ) {
    // pass sessionId to backEnd to get TransactionId
    const cardInfo: AccertifyCreateCardDto = {
      sessionId: sessionId,
    };
    const transactionId = await createPaymentMethod(cardInfo);
    let obj = { ...transactionId, ...formParameter, nameOnCard };
    props.onSuccess(obj);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formState.isFormValid) {
      return;
    }
    setShowLoader(true);
    formValues.current = formState.inputValues;

    setExternalErrorhandling({
      card_number: '',
      cardholder_name: '',
      security_code: '',
    });
    setShowExpMonthError(false);
    setShowExpYearError(false);
    setShowCommonErrors(false);

    window.PaymentSession.updateSessionFromForm('card');
  };

  const textChangeHandler = useCallback(
    (inputIdentifier: any, text: string, isValid: boolean) => {
      dispatchFormState({
        type: FormActionKind.FORM_INPUT_UPDATE,
        value: text,
        isValid,
        input: inputIdentifier,
      });
    },
    [dispatchFormState]
  );

  const validateCvvAndName = (cvv: any, name: any) => {
    if (cvv === undefined && name === undefined) {
      setExternalErrorhandling({
        ...externalErrorHandling,
        cardholder_name: 'Please enter the Cardholder name',
        security_code: 'Please enter the CVV code',
      });
      return false;
    }
    if (name === undefined) {
      setExternalErrorhandling({
        ...externalErrorHandling,
        cardholder_name: 'Please enter the Cardholder name',
      });
      return false;
    }
    if (cvv === undefined) {
      setErrorMessage('Please enter the CVV code');
      setModalHeader('Error');
      setShowInfoModal(true);
      return false;
    }
    return true;
  };

  const handleFieldErrors = (fieldError: any) => {
    const cardNum = fieldError.cardNumber;
    const expYear = fieldError.expiryYear;
    const expMonth = fieldError.expiryMonth;
    const cvv = fieldError.securityCode;
    if (cardNum) {
      const errMsg =
        cardNum === 'invalid'
          ? 'Please enter a valid credit card number'
          : 'Please enter the credit card number';
      setExternalErrorhandling({
        ...externalErrorHandling,
        card_number: errMsg,
      });
    }
    if (expYear) {
      const errMsg =
        expYear === 'invalid'
          ? 'Please enter a valid expiry year'
          : 'Please enter the expiry year';
      setExpYearError(errMsg);
      setShowExpYearError(true);
    }
    if (expMonth) {
      const errMsg =
        expMonth === 'invalid'
          ? 'Please enter a valid expiry month'
          : 'Please enter the expiry month';
      setExpMonthError(errMsg);
      setShowExpMonthError(true);
    }
    if (cvv) {
      const errMsg =
        cvv === 'invalid'
          ? 'Please enter a valid CVV code'
          : 'Please enter the CVV code';
      setExternalErrorhandling({
        ...externalErrorHandling,
        security_code: errMsg,
      });
    }
  };

  // HANDLE RESPONSE FROM ACCERTIFY
  const handleUpdateSessionResponse = (response: any) => {
    setShowLoader(false);
    if (!response.status) {
      return;
    }

    if ('ok' === response.status) {
      let date = new Date();
      let currentMonth = date.getMonth() + 1;
      let currentYear = date.getFullYear() % 100;
      const expiryYear = response.sourceOfFunds.provided.card.expiry.year;
      const expiryMonth = response.sourceOfFunds.provided.card.expiry.month;
      const cvv = response.sourceOfFunds.provided.card.securityCode;
      const name = response.sourceOfFunds.provided.card.nameOnCard;
      const brand = response.sourceOfFunds.provided.card.brand;
      //check if card has expired
      if (
        Number(expiryYear) === currentYear &&
        Number(expiryMonth) < currentMonth
      ) {
        setShowExpMonthError(true);
        setShowExpYearError(true);
        setExpMonthError('Card expired');
        setExpYearError('Card expired');
        return;
      }

      if (!validateCvvAndName(cvv, name)) return;

      if (brand !== 'VISA') {
        setErrorMessage(
          "We're sorry. We currently only support Visa cards. Please try again."
        );
        setModalHeader('Error');
        setShowInfoModal(true);
        return;
      }

      completeTransaction(response.session.id, formValues.current, name);
      return;
    }

    if ('fields_in_error' === response.status) {
      handleFieldErrors(response.errors);
    }

    const errMsg = response.errors.message;
    if ('request_timeout' === response.status) {
      setCommonErrors(errMsg);
      setShowCommonErrors(true);
    }

    if ('system_error' === response.status) {
      setCommonErrors(errMsg);
      setShowCommonErrors(true);
    }
  };

  const configureSession = () => {
    window.PaymentSession.configure({
      fields,
      frameEmbeddingMitigation: ['javascript'], //SPECIFY YOUR MITIGATION OPTION HERE
      callbacks: {
        initialized: () => {}, // HANDLE INITIALIZATION RESPONSE
        formSessionUpdate: (response) => handleUpdateSessionResponse(response),
      },
      interaction: {
        displayControl: {
          formatCard: 'EMBOSSED',
          invalidFieldCharacters: 'REJECT',
        },
      },
    });
  };

  const loadScript = (cb) => {
    const existingScript = document.getElementById('sessionJs');

    if (!existingScript) {
      const script = document.createElement('script');
      script.src = props.url;
      script.id = 'sessionJs';
      document.body.appendChild(script);

      script.onload = () => {
        if (cb) {
          cb();
        }
      };
    }

    if (existingScript && cb) cb();
  };

  useEffect(() => {
    loadScript(configureSession);
  }, []);

  const handleModalClose = () => {
    setShowInfoModal((showInfoModal) => !showInfoModal);
  };

  return (
    <DivBodyStyled>
      <Overlay showInfoModal={showInfoModal} />
      <Modal
        showModal={showInfoModal}
        message={errorMessage}
        header={modalHeader}
        handleClose={handleModalClose}
      />

      <Loader showLoader={showLoader}></Loader>

      <FormBodyStyled onSubmit={handleSubmit} showInfoModal={showInfoModal}>
        {PAYMENT_FORM_DATA.PaymentFormParameter.map((item, index) => {
          if (item.type === 'text' || item.type === 'number') {
            return (
              <TextInput
                label={item.label}
                type={item.type}
                id={item.id}
                ariaLabel={item.ariaLabel}
                tabIndex={index + 1}
                readOnly={item.readOnly}
                required={item.required}
                identifier={item.identifier}
                placeholder={item.placeholder}
                onChangehandler={textChangeHandler}
                uncontrolledErrorMsg={externalErrorHandling[item.id]}
                controlledRequiredErrorMsg={item.error}
                controlledValidationErrorMsg={item.validation}
                minLength={item.minLength}
                maxLength={item.maxLength}
                pattern={item.pattern}
              />
            );
          }
          if (item.type === 'drowdown' && item.id === 'state') {
            return (
              <DropdownInput
                label={item.label}
                labelName={item.labelName}
                ariaLabel={item.ariaLabel}
                dropdownValues={sortedStates}
                identifier={item.identifier}
                tabIndex={index + 1}
                onChangehandler={textChangeHandler}
                itemKey={item.itemKey}
                itemValue={item.itemValue}
                required={item.required}
                controlledErrorMsg={item.error}
              />
            );
          }
          if (item.type === 'dateAndCvv') {
            return (
              <DivStyled>
                <DivWidth>
                  <LabelStyled htmlFor={item.month.label}>
                    {item.month.labelName}
                  </LabelStyled>
                  <SelectStyledDate
                    id={item.month.id}
                    title={item.month.title}
                    aria-label={item.month.ariaLabel}
                    tabIndex={index + 1}
                  >
                    {getExpiryMonths().map((item) => {
                      return <option key={item}>{item}</option>;
                    })}
                  </SelectStyledDate>
                  {showExpMonthError ? (
                    <ErrorDiv>{expMonthError}</ErrorDiv>
                  ) : null}
                </DivWidth>
                <DivWidth>
                  <LabelStyled htmlFor="">{item.year.labelName}</LabelStyled>
                  <SelectStyledDate
                    id={item.year.id}
                    title={item.year.title}
                    aria-label={item.year.ariaLabel}
                    tabIndex={index + 2}
                  >
                    {getExpiryYears().map((item) => {
                      return <option key={item}>{item}</option>;
                    })}
                  </SelectStyledDate>

                  {showExpYearError ? (
                    <ErrorDiv>{expYearError}</ErrorDiv>
                  ) : null}
                </DivWidth>

                <DivWidth>
                  <TextInput
                    label={item.cvv.label}
                    type={item.cvv.type}
                    id={item.cvv.id}
                    ariaLabel={item.cvv.ariaLabel}
                    tabIndex={index + 3}
                    readOnly={item.cvv.readOnly}
                    required={item.cvv.required}
                    identifier={item.cvv.identifier}
                    placeholder={item.cvv.placeholder}
                    onChangehandler={textChangeHandler}
                    uncontrolledErrorMsg={externalErrorHandling[item.cvv.id]}
                  />
                </DivWidth>
              </DivStyled>
            );
          }
        })}

        {showCommonErrors && <ErrorDiv>{commonErrors}</ErrorDiv>}

        <CentreDiv>
          <ButtonStyled id="submitbutton" type="submit">
            {PAYMENT_FORM_DATA.BtnText}
          </ButtonStyled>
        </CentreDiv>
      </FormBodyStyled>
    </DivBodyStyled>
  );
};

export default PaymentForm;
