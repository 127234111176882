import Button from '../Button/Button';
import theme, { ButtonKind, TypographyStyle } from '../../theme/theme';
import styled, { ThemeProvider } from 'styled-components';
import Typography from '../Typography/Typography';

const ContainerDiv = styled.div`
  padding: 0em 1em;
  display: flex;
  vertical-align: middle;
  justify-content: center;
  flex-direction: column;
  color: ${(props) => props.theme.color.gray1};
`;

const HeadingText = styled(Typography)`
  font-weight: bold;
  padding: 1em 0em;
`;

const BodyText = styled(Typography)`
  margin-bottom: 2.5em;
`;

const ButtonStyled = styled(Button)`
  margin: 0.5em 0em;
`;

export interface IDeleteCardProps {
  handleDeleteConfirmation: () => void;
  disableDeletePayment: boolean;
  handleCancelDeleteConfirmation: () => void;
}

function ConfirmRemovePaymentMethod({
  handleDeleteConfirmation,
  disableDeletePayment,
  handleCancelDeleteConfirmation,
}: IDeleteCardProps) {
  function deleteCard() {
    handleDeleteConfirmation();
  }

  function cancelDeleteCard() {
    handleCancelDeleteConfirmation();
  }
  return (
    <ThemeProvider theme={theme}>
      <ContainerDiv>
        <HeadingText
          typographyStyle={TypographyStyle.MajorNormal}
          label="Confirm removal"
        />

        <BodyText
          typographyStyle={TypographyStyle.MinorNormal}
          label="By removing this card, it will no longer show up in your list of payment methods. Any active orders using this card will still be completed."
        />

        <ButtonStyled
          kind={ButtonKind.Primary}
          label="Remove payment method"
          onClick={deleteCard}
          disabled={disableDeletePayment}
        />

        <ButtonStyled
          kind={ButtonKind.Secondary}
          label="Cancel"
          onClick={cancelDeleteCard}
        />
      </ContainerDiv>
    </ThemeProvider>
  );
}

export default ConfirmRemovePaymentMethod;
