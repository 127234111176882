import React from 'react';
import { CardBrand } from '../../types/CardTypes';
import {
  AmericanExpress,
  Discover,
  Jcb,
  GenericCard,
  MasterCard,
  Visa,
} from './Icon';

export const CardBrandComponent = {
  VISA: Visa,
  MASTERCARD: MasterCard,
  AMERICAN_EXPRESS: AmericanExpress,
  DISCOVER: Discover,
  JCB: Jcb,
  DEFAULT: GenericCard,
};

export interface IPaymentIconProps {
  cardBrand: CardBrand | string;
  [x: string]: any;
}

function PaymentIcon({ cardBrand, ...props }: IPaymentIconProps) {
  const Icon = CardBrandComponent[cardBrand] || CardBrandComponent.DEFAULT;

  return <Icon {...props} />;
}

export default PaymentIcon;
