import React from 'react';
import styled from 'styled-components';

import { TypographyStyle } from '../../theme/theme';

//@ts-ignore
const TypographyStyled = styled.span<{ typographyStyle: TypographyStyle }>`
  line-height: ${(props) => props.theme.font[props.typographyStyle].lineHeight};
  font-size: ${(props) => props.theme.font[props.typographyStyle].fontSize};
  font-family: ${(props) => props.theme.font[props.typographyStyle].fontFamily};
  font-weight: ${(props) => props.theme.font[props.typographyStyle].fontWeight};
  opacity: 80%;
`;

export interface ITypographyProps {
  label: string | null;
  typographyStyle: TypographyStyle;
  [x: string]: any;
}

const Typography: React.FC<ITypographyProps> = ({
  label,
  typographyStyle,
  ...props
}) => {
  return (
    <TypographyStyled typographyStyle={typographyStyle} {...props}>
      {label}
    </TypographyStyled>
  );
};

export default Typography;
