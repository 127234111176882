import React from 'react';

import CardList from '../card-list/CardList';

const CardListFrame: React.FC = () => {
  const onInit = () => {
    window.xprops?.onInitHook();
  };

  const selectCard = (cardId: string) => {
    window?.xprops?.onCardSelected(cardId);
  };

  const addCard = () => {
    window?.xprops?.addCard();
  };

  const onResize = (width: number, height: number) => {
    window.xprops?.onResizeHook && window.xprops?.onResizeHook(width, height);
  };

  return (
    <CardList
      selectCard={selectCard}
      addCard={addCard}
      onInit={onInit}
      onResize={onResize}
    />
  );
};

export default CardListFrame;
