import styled from 'styled-components';

import { TypographyStyle } from '../../theme/theme';
import { Card } from '../../types/CardTypes';
import CardListItem from '../CardListItem/CardListItem';
import Typography from '../Typography/Typography';

export interface ICardListFormProps {
  cards: Card[];
  selectCard: (cardId: string) => void;
}

const StyledList = styled.ul`
  list-style-type: none;
  margin-block-start: 0.5em;
  margin-block-end: 0em;
  margin-left: 0;
  padding: 0;
`;

const StyledLineItem = styled.li`
  padding-bottom: 2em;

  cursor: pointer;
`;

function CardListForm(props: ICardListFormProps) {
  const selectCard = (cardId: string) => {
    //takes user to the edit card page
    props.selectCard(cardId);
  };

  const cards = props.cards
    .filter((card) => card !== undefined)
    .map((card) => (
      <StyledLineItem key={card.id} onClick={() => selectCard(card.id)}>
        <CardListItem
          lastFour={card.lastFour}
          cardType={card.cardBrand}
          isDefault={card.defaultCard}
          expDate={card.expDate}
        ></CardListItem>
      </StyledLineItem>
    ));
  const contentToDisplay =
    cards.length === 0 ? (
      <StyledLineItem style={{ paddingBottom: '1em' }}>
        <Typography
          typographyStyle={TypographyStyle.MinorNormal}
          label="No cards on file"
        />
      </StyledLineItem>
    ) : (
      cards
    );

  return <StyledList>{contentToDisplay}</StyledList>;
}

export default CardListForm;
