import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { TypographyStyle } from '../../theme/theme';
import Typography from '../Typography/Typography';

export interface ErrorBannerProps {
  title?: string;
  message: string;
  className?: string;
}

const ContainerDiv = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.color.errorBackground};
  padding: 15px;
  align-items: flex-start;
  margin: 0px 0px 20px 0px;
  color: ${(props) => props.theme.color.error};
`;

const MessageCotainer = styled.div`
  padding-left: 10px;
  display: flex;
  flex-direction: column;
`;

const HeaderStyled = styled(Typography)`
  max-height: 120px;
`;

const MessageStyled = styled(Typography)``;

const FontAwesomeIconStyled = styled(FontAwesomeIcon)`
  padding-top: 5px;
`;

const ErrorBanner = ({ title, message, className }: ErrorBannerProps) => (
  <ContainerDiv className={className}>
    <FontAwesomeIconStyled icon={faExclamationTriangle} />
    <MessageCotainer>
      {title && (
        <HeaderStyled
          typographyStyle={TypographyStyle.MinorBold}
          label={title}
        />
      )}
      <MessageStyled
        typographyStyle={TypographyStyle.MinorNormal}
        label={message}
      />
    </MessageCotainer>
  </ContainerDiv>
);
export default ErrorBanner;
