import * as Square from '@square/web-sdk';
import React, { useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

import theme from '../../theme/theme';
import Checkbox from '../Checkbox/Checkbox';
import ErrorBanner from '../ErrorBanner/ErrorBanner';
import { PAYMENT_PROCESSOR_ERRORS } from '../../constants/ConstantData';
import { getSquarePaymentConfig } from '../../api/payment-config.api';

export interface SquareCardInputProps {
  setCard: any;
  saveVisible: boolean;
  saveChecked: boolean;
  saveOnClick: (saveNewCardState: boolean) => void;
  defaultVisible: boolean;
  defaultChecked: boolean;
  defaultOnClick: (defautCardState: boolean) => void;
  setSquareIFrame?: any | undefined;
}

const CheckboxStyled = styled(Checkbox)`
  margin-bottom: 10px;
`;

const SkeletonIFrameStyled = styled(Skeleton)`
  margin-bottom: 38px;
`;

const SkeletonCheckboxStyled = styled(Skeleton)`
  display: block;
  margin-bottom: 10px;
`;

const SquareCardInput: React.FC<SquareCardInputProps> = (
  props: SquareCardInputProps
) => {
  const _isMounted = useRef(true);
  const [isIframeLoaded, setisIframeLoaded] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [applicationId, setApplicationId] = useState<string>();
  const [locationId, setLocationId] = useState<string>();
  const [payments, setPayments] = useState<Square.Payments | undefined>(
    undefined
  );

  const cardInputStyle = {
    '.input-container.is-focus': {
      borderColor: theme.color.primary,
    },
    input: {
      fontFamily: 'sans-serif',
      color: theme.color.gray1,
      fontSize: '16px',
    },
  };

  const setErrorStatus = (): void => {
    if (_isMounted.current) {
      setisIframeLoaded(false);
      setShowErrorMessage(true);
    }
  };

  async function loadCardElement(cardLoaded: (card: Square.Card) => void) {
    if (!payments && applicationId && locationId) {
      try {
        const paymentsRaw = await Square.payments(applicationId, locationId);
        if (_isMounted.current) {
          setPayments(paymentsRaw ? paymentsRaw : undefined);
        }
      } catch (e) {
        if (_isMounted.current) {
          props.setSquareIFrame && props.setSquareIFrame(true);
        }
        setErrorStatus();
      }
    }

    if (payments && !isIframeLoaded) {
      try {
        const rawCard = await payments.card({
          style: cardInputStyle,
        });
        await rawCard.attach('#card-container');
        cardLoaded(rawCard);
        setisIframeLoaded(true);
      } catch (e) {
        if (_isMounted.current) {
          props.setSquareIFrame && props.setSquareIFrame(true);
        }
        setErrorStatus();
      }
    }
  }

  function handleSaveCheckbox() {
    if (props.saveChecked) {
      props.defaultOnClick(false);
    }
    props.saveOnClick(!props.saveChecked);
  }

  function handleDefaultCheckbox() {
    if (!props.defaultChecked) {
      props.saveOnClick(true);
    }
    props.defaultOnClick(!props.defaultChecked);
  }

  useEffect(() => {
    loadCardElement((card: Square.Card) => {
      setisIframeLoaded(true);
      if (_isMounted.current) {
        props.setCard(card);
      }
    });
  });

  useEffect(() => {
    getSquarePaymentConfig()
      .then((res) => {
        setApplicationId(res.applicationId);
        setLocationId(res.locationId);
      })
      .catch(() => {
        setShowErrorMessage(true);
      });
    return () => {
      _isMounted.current = false;
    };
  }, []);

  return (
    <>
      {!isIframeLoaded && !showErrorMessage && (
        <>
          <SkeletonIFrameStyled height={99} data-testid={'iframe-skeleton'} />
          <SkeletonCheckboxStyled height={24} width={139} />
          <SkeletonCheckboxStyled height={24} width={208} />
        </>
      )}

      {!isIframeLoaded && showErrorMessage ? (
        <ErrorBanner
          title={PAYMENT_PROCESSOR_ERRORS.TITLE}
          message={PAYMENT_PROCESSOR_ERRORS.MSG}
        />
      ) : (
        <div id="card-container"></div>
      )}

      {isIframeLoaded && !showErrorMessage && (
        <>
          {props.saveVisible && (
            <CheckboxStyled
              label="Save for future use"
              checked={props.saveChecked}
              onClick={handleSaveCheckbox}
              data-testid={'save-checkbox'}
            />
          )}

          {props.defaultVisible && (
            <CheckboxStyled
              label="Set as default payment method"
              checked={props.defaultChecked}
              onClick={handleDefaultCheckbox}
              data-testid={'default-checkbox'}
            />
          )}
        </>
      )}
    </>
  );
};

export default SquareCardInput;
