import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { getPaymentCardDetails } from '../../api/payment.api';
import CardItem from '../../components/CardItem/CardItem';
import CardSkeleton from '../../components/CardSkeleton/CardSkeleton';
import ErrorBanner from '../../components/ErrorBanner/ErrorBanner';
import { PAYMENT_DETAILS_ERRORS } from '../../constants/ConstantData';
import { CardBrand } from '../../types/CardTypes';

const ContainerDiv = styled.div`
  padding: 1em;
  color: ${(props) => props.theme.color.gray1};
`;

export interface IPaymentDetailsProps {
  transactionId: string;
  onInit?: (isSuccess: boolean) => void;
  onResize?: (width: number, height: number) => void;
}

export interface IPaymentCardDetails {
  lastFour: string;
  cardBrand: CardBrand;
}

const StyledErrorBanner = styled(ErrorBanner)`
  margin: 0.5em 0;
`;

const PaymentDetails: React.FC<IPaymentDetailsProps> = (
  props: IPaymentDetailsProps
) => {
  const targetRef = useRef<HTMLDivElement>(null);
  const [card, setCard] = useState<IPaymentCardDetails | undefined>(undefined);
  const [errorOccurred, setErrorOccurred] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [errorTitleMessage, setErrorTitleMessage] = useState<string>('');

  async function getPaymentDetails() {
    try {
      const paymentCard: IPaymentCardDetails = await getPaymentCardDetails(
        props.transactionId
      );
      setCard(paymentCard);
      setErrorOccurred(false);
    } catch (error: any) {
      setErrorOccurred(true);
      setErrorTitleMessage(PAYMENT_DETAILS_ERRORS.TITLE);
      setErrorMessage(PAYMENT_DETAILS_ERRORS.MSG[error.code]);
    }
  }

  useEffect(() => {
    getPaymentDetails();
    props.onInit && props.onInit(true);
  }, []);

  useEffect(() => {
    if (targetRef.current) {
      props.onResize &&
        props.onResize(
          targetRef.current.offsetWidth,
          targetRef.current.offsetHeight
        );
    }
  }, [card, errorOccurred, errorMessage]);

  return (
    <ContainerDiv ref={targetRef}>
      {errorOccurred ? (
        <StyledErrorBanner title={errorTitleMessage} message={errorMessage} />
      ) : !card ? (
        <CardSkeleton />
      ) : (
        <CardItem lastFour={card.lastFour} cardType={card.cardBrand} />
      )}
    </ContainerDiv>
  );
};

export default PaymentDetails;
