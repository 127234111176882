import React from 'react';

import CardDetails from '../card-details/CardDetails';

const CardDetailsFrame: React.FC = () => {
  const onInit = () => {
    window.xprops?.onInitHook();
  };

  const onDeleteSuccess = () => {
    window.xprops?.onDeleteCardSuccessHook();
  };

  const onDeleteFailure = () => {
    window.xprops?.onDeleteCardFailureHook &&
      window.xprops?.onDeleteCardFailureHook();
  };

  const onEdit = (cardId: string) => {
    window.xprops?.onEditCard(cardId);
  };

  const onResize = (width: number, height: number) => {
    window.xprops?.onResizeHook && window.xprops?.onResizeHook(width, height);
  };

  return (
    <CardDetails
      cardId={window?.xprops?.cardId}
      onInit={onInit}
      onDeleteSuccess={onDeleteSuccess}
      onDeleteFailure={onDeleteFailure}
      editCard={onEdit}
      onResize={onResize}
    ></CardDetails>
  );
};

export default CardDetailsFrame;
