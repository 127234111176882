import { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

import { getCustomerCards } from '../../api/card.api';
import Button from '../../components/Button/Button';
import CardListForm from '../../components/CardListForm/CardListForm';
import { ButtonKind } from '../../theme/theme';
import { Card } from '../../types/CardTypes';
import ErrorBanner from '../../components/ErrorBanner/ErrorBanner';
import { CARD_LIST_ERRORS } from '../../constants/ConstantData';
import CardSkeleton from '../../components/CardSkeleton/CardSkeleton';

export interface ICardListProps {
  selectCard: (cardId: string) => void;
  addCard: () => void;
  onInit?: (isSuccess: boolean) => void;
  onResize?: (width: number, height: number) => void;
}

const StyledDiv = styled.div`
  padding: 0.5em 1em;
`;

const ButtonStyled = styled(Button)`
  padding: 0;
  height: 2em;
  text-align: left;
`;

const StyledErrorBanner = styled(ErrorBanner)`
  margin: 0.5em 0;
`;

function CardList(props: ICardListProps) {
  const targetRef = useRef<HTMLDivElement>(null);
  const [cardList, setCardList] = useState<Card[]>([]);
  const [errorOccurred, setErrorOccurred] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [errorTitleMessage, setErrorTitleMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const addPaymentButtonHandler = () => {
    props.addCard();
  };

  async function fetchCustomerCards() {
    try {
      const cards = await getCustomerCards();
      setCardList(cards);
      setIsLoading(false);
      setErrorOccurred(false);
    } catch (error: any) {
      setErrorOccurred(true);
      setErrorTitleMessage(CARD_LIST_ERRORS.TITLE);
      setErrorMessage(CARD_LIST_ERRORS.MSG[error.code]);
    }
  }

  useEffect(() => {
    fetchCustomerCards();
    props.onInit && props.onInit(true);
  }, []);

  useEffect(() => {
    if (targetRef.current) {
      props.onResize &&
        props.onResize(
          targetRef.current.offsetWidth,
          targetRef.current.offsetHeight
        );
    }
  }, [cardList, isLoading, errorOccurred, errorMessage]);

  return (
    <StyledDiv ref={targetRef}>
      {errorOccurred ? (
        <StyledErrorBanner title={errorTitleMessage} message={errorMessage} />
      ) : (
        <>
          {isLoading ? (
            <>
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
            </>
          ) : (
            <CardListForm cards={cardList} selectCard={props.selectCard} />
          )}
          <ButtonStyled
            kind={ButtonKind.Tertiary}
            label="Add payment method"
            onClick={addPaymentButtonHandler}
          />
        </>
      )}
    </StyledDiv>
  );
}

export default CardList;
