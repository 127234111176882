import React from 'react';
import styled, { keyframes } from 'styled-components';

let spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }`;

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.5;
`;

const Spinner = styled.div.attrs({
  'data-testid': 'spinner',
})`
  position: absolute;
  top: 45%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 60px;
  height: 60px;
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  animation: ${spin} 2s linear infinite;
`;

export interface ILoaderProps {
  showLoader: boolean;
}
const Loader: React.FC<ILoaderProps> = (props: ILoaderProps) => {
  return (
    <>
      {!props.showLoader ? null : (
        <Overlay>
          <Spinner />
        </Overlay>
      )}
    </>
  );
};

export default Loader;
