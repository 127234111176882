import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

import theme, { TypographyStyle } from '../../theme/theme';
import { CardBrand } from '../../types/CardTypes';
import CardItem from '../CardItem/CardItem';
import { flagIfExpired, flagIfExpiring } from '../ExpireService/ExpireService';
import Typography from '../Typography/Typography';

const DivStyled = styled.div`
  display: flex;
  vertical-align: middle;
  align-items: left;
  flex-direction: column;
  cursor: pointer;
`;

const CardListDivStyled = styled.div`
  display: flex;
  vertical-align: middle;
  justify-content: center;
  flex-direction: column;
`;

export const CardSubText = styled(Typography)`
  opacity: ${(props) => (props.expired ? '100%' : '65%')};
  padding-left: 4em;
  color: ${(props) => (props.expired ? props.theme.color.error : '')};
  padding-top: ${(props) => (props.expired && props.isDefault ? '0.25em' : '')};
`;

const LineBreak = styled.div`
  display: block;
  flex-direction: row;
`;

export interface ICardListItemProps {
  lastFour: string;
  cardType: CardBrand;
  isDefault?: boolean;
  expDate: string;
}
function CardListItem(props: ICardListItemProps) {
  return (
    <ThemeProvider theme={theme}>
      <DivStyled {...props}>
        <LineBreak>
          <CardItem lastFour={props.lastFour} cardType={props.cardType} />
        </LineBreak>
        <LineBreak>
          <CardListDivStyled>
            {props.isDefault && (
              <CardSubText
                typographyStyle={TypographyStyle.MinorQuiet}
                label={'Default payment method'}
              />
            )}
            {flagIfExpiring(props.expDate) && (
              <CardSubText
                typographyStyle={TypographyStyle.MinorQuiet}
                label={'Expiring ' + props.expDate}
                expired
                isDefault={!!props.isDefault}
              />
            )}
            {flagIfExpired(props.expDate) && (
              <CardSubText
                typographyStyle={TypographyStyle.MinorQuiet}
                label={'This card has expired'}
                expired
                isDefault={!!props.isDefault}
              />
            )}
          </CardListDivStyled>
        </LineBreak>
      </DivStyled>
    </ThemeProvider>
  );
}

CardListItem.defaultProps = {
  isDefault: false,
};

export default CardListItem;
