import * as Square from '@square/web-sdk';
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

import { editPaymentMethod } from '../../api/card.api';
import Button from '../../components/Button/Button';
import ErrorBanner from '../../components/ErrorBanner/ErrorBanner';
import SquareCardInput from '../../components/SquareCardInput/SquareCardInput';
import { EDIT_PAYMENT_ERRORS } from '../../constants/ConstantData';
import { ButtonKind } from '../../theme/theme';
import { ICardDetails } from '../../types/CardTypes';

const ContainerDiv = styled.div`
  padding: 1em;
  color: ${(props) => props.theme.color.gray1};
`;

export interface IEditPaymentProps {
  cardId: string;
  onSuccess: (cardId: string) => void;
  onFailure: (cardId: string) => void;
  onInit?: (isSuccess: boolean) => void;
  onResize?: (width: number, height: number) => void;
}

const EditPayment: React.FC<IEditPaymentProps> = (props: IEditPaymentProps) => {
  const targetRef = useRef<HTMLDivElement>(null);
  const [card, setCard] = useState<Square.Card | undefined>(undefined);
  const [errorOccurred, setErrorOccurred] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [errorTitle, setErrorTitle] = useState<string>('');
  const [disableSave, setDisableSave] = useState<boolean>(false);

  const saveButtonClickHandler = async () => {
    setDisableSave(true);
    if (!card) {
      return;
    }
    try {
      const result = await card.tokenize();
      if (result.status === 'OK') {
        const cardNonce: string = result.token!;
        setErrorOccurred(false);
        const response: { card: ICardDetails } = await editPaymentMethod(
          props.cardId,
          cardNonce
        );
        props.onSuccess(response.card.id);
      } else {
        setDisableSave(false);
      }
    } catch (error: any) {
      setDisableSave(false);
      setErrorTitle(EDIT_PAYMENT_ERRORS.TITLE);
      setErrorMessage(
        EDIT_PAYMENT_ERRORS.MSG[error.code] || EDIT_PAYMENT_ERRORS.MSG.ERROR
      );
      setErrorOccurred(true);
      props.onFailure(props.cardId);
    }
  };

  useEffect(() => {
    setErrorOccurred(false);
  }, [card]);

  useEffect(() => {
    if (targetRef.current) {
      props.onResize &&
        props.onResize(
          targetRef.current.offsetWidth,
          targetRef.current.offsetHeight
        );
    }
  }, [card, errorMessage, errorOccurred]);

  return (
    <ContainerDiv ref={targetRef}>
      {errorOccurred && (
        <ErrorBanner title={errorTitle} message={errorMessage} />
      )}
      <SquareCardInput
        setCard={setCard}
        saveVisible={false}
        saveChecked={false}
        saveOnClick={() => {}}
        defaultVisible={false}
        defaultChecked={true}
        defaultOnClick={() => {}}
      />
      <Button
        kind={ButtonKind.Primary}
        label="Save"
        onClick={saveButtonClickHandler}
        disabled={card === undefined || disableSave}
      />
    </ContainerDiv>
  );
};

export default EditPayment;
