import { get } from './rest-client';

export interface SquarePaymentConfig {
  applicationId: string;
  locationId: string;
}

export const getSquarePaymentConfig = (): Promise<SquarePaymentConfig> => {
  return get('/payment-config');
};
