import { DefaultTheme } from 'styled-components';

export enum TypographyStyle {
  HeadlineNormal = 'headlineNormal',
  MajorNormal = 'majorNormal',
  MajorQuiet = 'majorQuiet',
  MinorNormal = 'minorNormal',
  MinorBold = 'minorBold',
  MinorQuiet = 'minorQuiet',
  MinorSuperQuiet = 'minorSuperQuiet',
}

export enum ButtonKind {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
}

const theme: DefaultTheme = {
  color: {
    primary: '#447B9D',
    onPrimary: 'white',
    gray1: '#1B2632',
    gray2: '#989DA2',
    gray3: '#48525B',
    gray4: 'rgba(27, 38, 50, 0.65)',
    lightgray: 'rgba(209, 212, 214, 0.6)',
    error: '#ce3617',
    errorBackground: 'rgba(206, 54, 23, 0.1);',
  },
  font: {
    headlineNormal: {
      fontFamily: 'Avenir',
      fontSize: '1.75em',
      lineHeight: '2em',
    },
    majorNormal: {
      fontFamily: "'Source Sans Pro', sans-serif",
      fontSize: '1.25em',
      lineHeight: '1.5em',
    },
    majorQuiet: {
      fontFamily: "'Source Sans Pro', sans-serif",
      fontSize: '1em',
      lineHeight: '1.5em',
    },
    minorNormal: {
      fontFamily: "'Source Sans Pro', sans-serif",
      fontSize: '1em',
      lineHeight: '1.5em',
    },
    minorBold: {
      fontFamily: "'Source Sans Pro', sans-serif",
      fontSize: '1em',
      lineHeight: '1.5em',
      fontWeight: 'bold',
    },
    minorQuiet: {
      fontFamily: "'Source Sans Pro', sans-serif",
      fontSize: '0.75em',
      lineHeight: '1em',
    },
  },
};

export default theme;
