import React, { useEffect, useReducer } from 'react';
import styled from 'styled-components';
import { ErrorDiv } from '../AccertifyCardInput/AccertifyStyles';

const DivStyled = styled.div`
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
`;

const LabelStyled = styled.label`
  color: #3d3d3d;
  display: block;
  font-family: system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
`;

const InputStyled = styled.input`
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
`;

enum InputActionKind {
  INPUT_CHANGE = 'INPUT_CHANGE',
  INPUT_BLUR = 'INPUT_BLUR',
}

export interface IInputProps {
  label: string;
  type: string;
  id: string;
  name?: string;
  ariaLabel: string;
  tabIndex: number;
  readOnly: boolean;
  required: boolean;
  placeholder: string;
  onChangehandler: (inputIdentifier: any, value: any, isValid: boolean) => void;
  uncontrolledErrorMsg?: string;
  controlledRequiredErrorMsg?: string;
  controlledValidationErrorMsg?: string;
  identifier: string;
  maxLength?: number;
  minLength?: number;
  pattern?: any;
}

interface IInputAction {
  type: InputActionKind;
  value?: string;
  isValid?: boolean;
}

interface IInputState {
  value: any;
  isValid: any;
  touch: boolean;
}

const inputReducer = (
  state: IInputState,
  action: IInputAction
): IInputState => {
  switch (action.type) {
    case InputActionKind.INPUT_CHANGE:
      return {
        ...state,
        value: action.value,
        isValid: action.isValid,
      };
    case InputActionKind.INPUT_BLUR:
      return {
        ...state,
        touch: true,
      };
    default:
      return state;
  }
};

const TextInput: React.FC<IInputProps> = (props: IInputProps) => {
  const [inputState, dispatchInputState] = useReducer(inputReducer, {
    value: '',
    isValid: false,
    touch: false,
  });

  const { onChangehandler, identifier } = props;

  useEffect(() => {
    if (inputState.touch) {
      props.required
        ? onChangehandler(identifier, inputState.value, inputState.isValid)
        : onChangehandler(identifier, inputState.value, true);
    }
  }, [inputState, onChangehandler, identifier]);

  const onSelect = (text) => {
    let isValid = true;

    if (props.required && text.trim().length === 0) {
      isValid = false;
    }

    if (props.maxLength != null && text.length > props.maxLength) {
      isValid = false;
    }

    if (props.minLength != null && text.length < props.minLength) {
      isValid = false;
    }

    if (text.length > 0 && props.pattern != null && !props.pattern.test(text)) {
      isValid = false;
    }

    dispatchInputState({
      type: InputActionKind.INPUT_CHANGE,
      value: text,
      isValid,
    });
  };

  const lostFocusHandler = () => {
    dispatchInputState({ type: InputActionKind.INPUT_BLUR });
  };

  return (
    <DivStyled>
      <LabelStyled>{props.label}</LabelStyled>
      <InputStyled
        type={props.type}
        maxLength={props.maxLength}
        id={props.id}
        name={props.name}
        value={inputState.value}
        aria-label={props.ariaLabel}
        onChange={(e) => onSelect(e.target.value)}
        placeholder={props.placeholder}
        required={props.required}
        readOnly={props.readOnly}
        onBlur={lostFocusHandler}
        autoComplete="new-password"
      />
      {!inputState.isValid && inputState.value && (
        <ErrorDiv>{props.controlledValidationErrorMsg}</ErrorDiv>
      )}
      {!inputState.value && inputState.touch && (
        <ErrorDiv>{props.controlledRequiredErrorMsg}</ErrorDiv>
      )}
      {props.uncontrolledErrorMsg && props.uncontrolledErrorMsg.length > 0 && (
        <ErrorDiv>{props.uncontrolledErrorMsg}</ErrorDiv>
      )}
    </DivStyled>
  );
};

export default TextInput;
