import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

import {
  getCardDetails,
  removePaymentMethod,
  setAsDefaultPaymentMethod,
} from '../../api/card.api';
import ConfirmRemovePaymentMethod from '../../components/ConfirmRemovePaymentMethod/ConfirmRemovePaymentMethod';
import PaymentMethodDeleted from '../../components/PaymentMethodDeleted/PaymentMethodDeleted';
import ViewCardDetails from '../../components/ViewCardDetails/ViewCardDetails';
import { ICardDetails } from '../../types/CardTypes';
import ErrorBanner from '../../components/ErrorBanner/ErrorBanner';
import { CARD_DETAILS_ERRORS } from '../../constants/ConstantData';

export interface ICardDetailsProps {
  cardId: string;
  onInit?: (isSuccess: boolean) => void;
  onDeleteSuccess: () => void;
  onDeleteFailure: () => void;
  editCard: (cardId: string) => void;
  onResize?: (width: number, height: number) => void;
}

const ErrorBannerStyled = styled(ErrorBanner)`
  margin: 1em 1em 0 1em;
`;

const CardDetails: React.FC<ICardDetailsProps> = ({
  cardId,
  onInit,
  onDeleteSuccess,
  onDeleteFailure,
  editCard,
  onResize,
}) => {
  const targetRef = useRef<HTMLDivElement>(null);
  const [cardDetails, setCardDetails] = useState<ICardDetails | undefined>(
    undefined
  );
  const [cardStatus, setCardStatus] = useState<string>('active');
  const [errorOccurred, setErrorOccurred] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [errorTitle, setErrorTitle] = useState<string>('');
  const [disableDefaultPayment, setDisableDefaultPayment] =
    useState<boolean>(false);
  const [disableDeletePayment, setDisableDeletePayment] =
    useState<boolean>(false);

  async function handleSetAsDefaultCardClick() {
    //API call for setting card as default
    setDisableDefaultPayment(true);
    try {
      const updatedCardDetails = await setAsDefaultPaymentMethod(cardId);
      setCardDetails(updatedCardDetails);
      setErrorOccurred(false);
    } catch (error: any) {
      setDisableDefaultPayment(false);
      handleError(CARD_DETAILS_ERRORS.TITLE.DEFAULT_CARD, error);
    }
  }

  async function handleDeleteConfirmation() {
    setDisableDeletePayment(true);
    try {
      cardDetails && (await removePaymentMethod(cardDetails.id));
      setCardStatus('deleted');
      setErrorOccurred(false);
    } catch (error: any) {
      setDisableDeletePayment(false);
      onDeleteFailure();
      handleError(CARD_DETAILS_ERRORS.TITLE.DELETE_CARD, error);
    }
  }

  async function fetchCardDetails(cardId: string): Promise<void> {
    try {
      setCardDetails(await getCardDetails(cardId));
    } catch (error: any) {
      setCardStatus('');
      handleError(CARD_DETAILS_ERRORS.TITLE.LOAD_CARD, error);
    }
  }

  function handleError(title: string, error: any) {
    setErrorTitle(title);
    setErrorMessage(
      CARD_DETAILS_ERRORS.MSG[error.code] || CARD_DETAILS_ERRORS.MSG.ERROR
    );
    setErrorOccurred(true);
  }

  useEffect(() => {
    fetchCardDetails(cardId);
    onInit && onInit(true);
  }, [onInit, cardId]);

  useEffect(() => {
    setErrorOccurred(false);
  }, [cardStatus]);

  useEffect(() => {
    if (targetRef.current) {
      onResize &&
        onResize(targetRef.current.offsetWidth, targetRef.current.offsetHeight);
    }
  }, [cardDetails, cardStatus, errorOccurred, errorMessage]);

  const ErrorComponent = (
    <ErrorBannerStyled title={errorTitle} message={errorMessage} />
  );

  if (errorOccurred && !cardDetails) return ErrorComponent;
  if (!cardDetails) return null;
  return (
    <div ref={targetRef}>
      {errorOccurred && ErrorComponent}
      {cardStatus === 'active' && (
        <ViewCardDetails
          cardDetails={cardDetails}
          handleEditCardClick={() => editCard(cardId)}
          handleSetAsDefaultCardClick={handleSetAsDefaultCardClick}
          disableDefaultPayment={disableDefaultPayment}
          handleDeleteCardClick={() => setCardStatus('confirmDelete')}
        />
      )}

      {cardStatus === 'confirmDelete' && (
        <ConfirmRemovePaymentMethod
          handleDeleteConfirmation={handleDeleteConfirmation}
          disableDeletePayment={disableDeletePayment}
          handleCancelDeleteConfirmation={() => setCardStatus('active')}
        />
      )}

      {cardStatus === 'deleted' && (
        <PaymentMethodDeleted
          deletedCardDetails={cardDetails}
          handleOkClick={onDeleteSuccess}
        />
      )}
    </div>
  );
};

export default CardDetails;
