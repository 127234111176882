import * as Square from '@square/web-sdk';
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

import { createPaymentMethod, SquareCreateCardDto } from '../../api/card.api';
import Button from '../../components/Button/Button';
import ErrorBanner from '../../components/ErrorBanner/ErrorBanner';
import SquareCardInput from '../../components/SquareCardInput/SquareCardInput';
import { ButtonKind } from '../../theme/theme';
import { ADD_PAYMENT_ERRORS } from '../../constants/ConstantData';
import AccertifyCardInput from '../../components/AccertifyCardInput/AccertifyCardInput';

const ContainerDiv = styled.div`
  padding: 1em;
  color: ${(props) => props.theme.color.gray1};
`;
export interface IAddPaymentMethodProps {
  onSuccess: (cardId: string) => void;
  onFailure: () => void;
  onInit?: () => void;
  onResize?: (width: number, height: number) => void;
  clientId: string;
  url?: string;
  states?: any[];
}

const StyledDivSmall = styled.form`
  padding-bottom: 48px;
`;

const AddPaymentMethod: React.FC<IAddPaymentMethodProps> = (
  props: IAddPaymentMethodProps
) => {
  const targetRef = useRef<HTMLDivElement>(null);
  const [card, setCard] = useState<Square.Card | undefined>(undefined);
  const [defaultCard, setDefaultCard] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorTitle, setErrorTitle] = useState<string>('');
  const [saveCardFailed, setSaveCardFailed] = useState<boolean>(false);
  const [disableSave, setDisableSave] = useState<boolean>(false);

  async function eventHandler() {
    setDisableSave(true);
    saveCardFailed && setSaveCardFailed(false);
    if (!card) {
      return;
    }

    try {
      const result = await card.tokenize();
      if (result.status === 'OK') {
        const cardInfo: SquareCreateCardDto = {
          cardNonce: result.token || '',
          defaultCard,
        };
        const { id } = await createPaymentMethod(cardInfo);
        props.onSuccess(id);
      } else {
        setDisableSave(false);
      }
    } catch (error: any) {
      setDisableSave(false);
      props.onFailure();
      setSaveCardFailed(true);
      setErrorTitle(ADD_PAYMENT_ERRORS.TITLE);
      setErrorMessage(
        ADD_PAYMENT_ERRORS.MSG[error.code] || ADD_PAYMENT_ERRORS.MSG.ERROR
      );
    }
  }

  useEffect(() => {
    props.onInit && props.onInit();
  }, []);

  useEffect(() => {
    if (targetRef.current) {
      props.onResize &&
        props.onResize(
          targetRef.current.offsetWidth,
          targetRef.current.offsetHeight
        );
    }
  }, [card, saveCardFailed]);

  return (
    <ContainerDiv ref={targetRef}>
      {saveCardFailed && (
        <ErrorBanner title={errorTitle} message={errorMessage} />
      )}

      {props.url && props.states && props.clientId === 'ACCERTIFY' ? (
        <AccertifyCardInput
          url={props.url}
          states={props.states}
          onSuccess={props.onSuccess}
        />
      ) : (
        <form id="add-payment-method">
          <SquareCardInput
            setCard={setCard}
            saveVisible={false}
            saveChecked={false}
            saveOnClick={() => {}}
            defaultVisible={true}
            defaultChecked={defaultCard}
            defaultOnClick={() => setDefaultCard(!defaultCard)}
          />

          <StyledDivSmall />
          <Button
            kind={ButtonKind.Primary}
            id="card-button"
            onClick={eventHandler}
            label="Save"
            disabled={card === undefined || disableSave}
          />
        </form>
      )}
    </ContainerDiv>
  );
};

export default AddPaymentMethod;
