import React, { ReactElement } from 'react';
import styled from 'styled-components';

import { TypographyStyle } from '../../theme/theme';
import Typography from '../Typography/Typography';

export interface ICheckboxProps {
  label: string;
  checked: boolean;
  onClick: () => void;
  [x: string]: any;
}

const StyledCheckbox = styled.input.attrs({
  type: 'checkbox',
})`
  width: 1rem;
  height: 1rem;
  box-sizing: border-box;
  -webkit-width: 0.125rem;
  -webkit-height: 0.125rem;
  -webkit-box-sizing: border-box;
  padding-left: 12px;
  cursor: pointer;
  color: ${(props) => props.theme.color.gray2};
`;

const StyledLabel = styled.label`
  color: ${(props) => props.theme.color.gray3};
  padding-left: 12px;
`;

const DivStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Checkbox = ({
  label,
  checked,
  onClick,
  ...props
}: ICheckboxProps): ReactElement => (
  <DivStyled {...props}>
    <StyledCheckbox id={label} checked={checked} onChange={onClick} />
    <StyledLabel
      htmlFor={label}
      title={`${label} checkbox `}
      data-testid={`${label} label`}
    >
      <Typography typographyStyle={TypographyStyle.MinorNormal} label={label} />
    </StyledLabel>
  </DivStyled>
);
export default Checkbox;
