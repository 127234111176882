import React from 'react';
import styled from 'styled-components';

export interface IModalProps {
  showModal: boolean;
  message: String;
  header: String;
  handleClose: () => void;
}

const ModalStyled = styled.div`
  background: white;
  border: 1px solid #ccc;
  transition: 1.1s ease-out;
  border-radius: 6px;
  visibility: visible;
  position: absolute;
  top: 30%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 1055;
  width: 250px;
  min-height: 20%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  display: block;

  @media (min-width: 351px) {
    width: 320px;
  }

  @media (min-width: 401px) {
    width: 330px;
  }

  @media (min-width: 501px) {
    width: 440px;
  }
`;

const ModalContent = styled.div`
  position: relative;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  outline: 0;
  -webkit-box-shadow: 0 3px 9px rgb(0 0 0 / 50%);
  box-shadow: 0 3px 9px rgb(0 0 0 / 50%);
`;
const ModalHeader = styled.h1`
  padding: 1rem;
  margin: 0;
  font-size: 18px !important;
`;

const HeaderDiv = styled.div`
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
`;

const ModalBody = styled.div`
  position: relative;
  padding: 15px;
`;

const ModalFooter = styled.div`
  background-color: #f5f5f5;
  padding: 10px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
`;

const ModalCancelButton = styled.button`
  padding: 0;
  background: 0 0;
  border: 0;
  float: right;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.2;
`;

const ModalCloseButton = styled.button`
  padding: 6px 35px 12px 35px;
  font-size: 16px !important;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
  display: inline-block;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  border: 1px solid transparent;
  border-radius: 4px;
`;

const Modal: React.FC<IModalProps> = (props: IModalProps) => {
  return (
    <>
      {!props.showModal ? null : (
        <ModalStyled>
          <ModalContent>
            <HeaderDiv>
              <ModalCancelButton type="button" onClick={props.handleClose}>
                x
              </ModalCancelButton>
              <ModalHeader>{props.header}</ModalHeader>
            </HeaderDiv>

            <ModalBody>
              <span>{props.message}</span>
            </ModalBody>

            <ModalFooter>
              <ModalCloseButton type="button" onClick={props.handleClose}>
                Close
              </ModalCloseButton>
            </ModalFooter>
          </ModalContent>
        </ModalStyled>
      )}
    </>
  );
};
export default Modal;
