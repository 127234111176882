import React from 'react';

import PaymentDetails from '../payment-details/PaymentDetails';

const PaymentDetailsFrame: React.FC = () => {
  const onInit = () => {
    window.xprops?.onInitHook();
  };

  const onResize = (width: number, height: number) => {
    window.xprops?.onResizeHook && window.xprops?.onResizeHook(width, height);
  };

  return (
    <PaymentDetails
      transactionId={window.xprops?.transactionId}
      onInit={onInit}
      onResize={onResize}
    />
  );
};

export default PaymentDetailsFrame;
