import wretch, { Wretcher } from 'wretch';
import {
  authMiddleware,
  authCatcherMiddleware,
  logTracerMiddleware,
} from './helper';

let apiClient = wretch('/api');
apiClient = apiClient.middlewares([
  authMiddleware,
  authCatcherMiddleware,
  logTracerMiddleware,
]);

export default apiClient;

export function getBaseClient(): Wretcher {
  return apiClient;
}

export function applyDefer(callback: any) {
  apiClient = apiClient.defer(callback);
}

export function get(url: string, queryParams?: object): Promise<any> {
  return apiClient
    .url(url)
    .query(queryParams ?? {})
    .get()
    .res()
    .then((res) => res.json());
}

export function post(
  url: string,
  body: object,
  headers?: { [x: string]: string }
): Promise<any> {
  return apiClient
    .url(url)
    .headers(headers ?? {})
    .post(body)
    .res()
    .then((res) => res.json());
}

export function patch(url: string, body: object): Promise<any> {
  return apiClient
    .url(url)
    .patch(body)
    .res()
    .then((res) => res.json());
}

export function put(url: string, body: object): Promise<any> {
  return apiClient
    .url(url)
    .put(body)
    .res()
    .then((res) => res.json());
}

export function del(url: string): Promise<void> {
  return apiClient.url(url).delete().res();
}
