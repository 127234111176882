import styled from 'styled-components';

interface IFormProps {
  showInfoModal: boolean;
}

export const ButtonStyled = styled.button`
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
  border-radius: 0.25rem;
  margin-top: 20px !important;
  margin-bottom: 10px !important;
  font-size: 18px !important;
  font-family: sans-serif;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  width: 100%;
`;

export const CentreDiv = styled.div`
  text-align: center !important;
`;

export const DivBodyStyled = styled.div`
  padding: 0px;
`;

export const DivStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const DivWidth = styled.div`
  width: 30%;
  @media (max-width: 350px) {
    max-width: 24%;
  }
`;

export const ErrorDiv = styled.div`
  color: red;
  font-size: 0.75em;
`;

export const FormBodyStyled = styled.form.attrs({
  autoComplete: 'off',
})`
  background: white;
  border: 1px solid #dedede;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0 auto;
  max-width: 550px;
  padding: 30px 20px;
  opacity: ${(props: IFormProps) => (props.showInfoModal ? '50%' : '100%')};
  pointer-events: ${(props: IFormProps) =>
    props.showInfoModal ? 'none' : 'visible'}; ;
`;

export const LabelStyled = styled.label`
  color: #3d3d3d;
  display: block;
  font-family: system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  padding-top: 10px;
`;

export const SelectStyledDate = styled.select.attrs({
  readOnly: 'readonly',
})`
  border: 1px solid #dedede;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 10px 10px 14px 10px;
  width: 100%;
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 95% 50%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
  display: ${(props: IFormProps) => (props.showInfoModal ? 'block' : 'none')};
`;
