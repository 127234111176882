interface Map {
  [key: string]: string | any;
}

export const DEFAULT_ERRORS: Map = {
  INVALID_TOKEN:
    'We ran into an unexpected error. Please navigate back to the account page, and try again.',
  ERROR: 'We ran into an unexpected error. Please try again.',
};

export const PAYMENT_PROCESSOR_ERRORS: Map = {
  TITLE: 'Contact Us',
  MSG: 'There was an issue connecting to our payment processor. Please call us for support at 844-447-1783.',
};

export const ADD_PAYMENT_ERRORS: Map = {
  TITLE: 'Could not add card',
  MSG: {
    INVALID_TOKEN: DEFAULT_ERRORS.INVALID_TOKEN,
    ERROR: DEFAULT_ERRORS.ERROR,
  },
};

export const CARD_DETAILS_ERRORS: Map = {
  TITLE: {
    DEFAULT_CARD: 'Could not set card as default',
    LOAD_CARD: 'Could not load card details',
    DELETE_CARD: 'Could not delete card',
  },
  MSG: {
    INVALID_TOKEN: DEFAULT_ERRORS.INVALID_TOKEN,
    ERROR: DEFAULT_ERRORS.ERROR,
  },
};

export const CARD_LIST_ERRORS: Map = {
  TITLE: 'Could not load cards',
  MSG: {
    INVALID_TOKEN: DEFAULT_ERRORS.INVALID_TOKEN,
    ERROR: DEFAULT_ERRORS.ERROR,
  },
};

export const CREATE_PAYMENT_ERRORS: Map = {
  TITLE: 'Could not complete transaction',
  MSG: {
    INVALID_TOKEN:
      'We ran into an unexpected error. Please navigate back to select your fulfillment options, and try again.',
    BAD_CARD: 'Please verify the card information and try again.',
    INSUFFICIENT_FUND: 'The card does not have sufficient funds.',
    ERROR: DEFAULT_ERRORS.ERROR,
  },
};

export const EDIT_PAYMENT_ERRORS: Map = {
  TITLE: 'Could not edit card',
  MSG: {
    INVALID_TOKEN: DEFAULT_ERRORS.INVALID_TOKEN,
    ERROR: DEFAULT_ERRORS.ERROR,
  },
};

export const PAYMENT_FORM_DATA: Map = {
  PaymentFormParameter: [
    {
      id: 'card_number',
      type: 'text',
      label: 'Card Number',
      ariaLabel: 'enter your card number',
      identifier: 'cardNumber',
      placeholder: '',
      readOnly: true,
      required: false,
    },
    {
      type: 'dateAndCvv',
      readOnly: true,
      month: {
        id: 'expiry_month',
        label: 'expiry_month',
        labelName: 'Exp. Month',
        title: 'expiry month',
        ariaLabel: 'two digit expiry month',
        readOnly: true,
        required: false,
      },
      year: {
        id: 'expiry_year',
        label: 'expiry_year',
        labelName: 'Exp. year',
        title: 'expiry year',
        ariaLabel: 'two digit expiry year',
        readOnly: true,
        required: false,
      },
      cvv: {
        id: 'security_code',
        type: 'text',
        label: 'CVV Code',
        identifier: 'cvvCode',
        ariaLabel: 'three digit CCV security code',
        placeholder: '',
        readOnly: true,
        required: false,
      },
    },

    {
      id: 'cardholder_name',
      type: 'text',
      label: 'CardHolder Name',
      identifier: 'cardHolderName',
      ariaLabel: 'enter card holder name',
      placeholder: '',
      readOnly: true,
      required: false,
    },
    {
      id: 'address_line_one',
      type: 'text',
      label: 'Street Address',
      identifier: 'addressLineOne',
      ariaLabel: 'enter address line 1',
      readOnly: false,
      required: true,
      error: 'Street address is required',
    },
    {
      id: 'address_line_two',
      type: 'text',
      label: '',
      identifier: 'addressLinetwo',
      ariaLabel: 'enter address line 2',
      placeholder: 'Apt, Suite, Unit, etc. (optional)',
      readOnly: false,
      required: false,
    },
    {
      id: 'zip',
      type: 'text',
      label: 'Zip',
      identifier: 'zip',
      ariaLabel: 'enter your zip',
      placeholder: '',
      readOnly: false,
      required: true,
      error: 'Zip code is required',
      validation:
        'Invalid Zip code. Please enter either 5 digits or 9 digits as zip code',
      maxLength: 9,
      minLength: 5,
      pattern: /^\d{5}(?:\d{4})?$/g,
    },
    {
      id: 'city',
      type: 'text',
      label: 'City',
      identifier: 'city',
      ariaLabel: 'enter your city',
      placeholder: '',
      readOnly: false,
      required: true,
      error: 'City name is required',
      validation: 'Please enter a valid city name',
      pattern: /^[a-zA-Z\s]*$/,
    },
    {
      id: 'state',
      type: 'drowdown',
      label: 'State',
      labelName: 'State',
      identifier: 'state',
      ariaLabel: 'Select your state',
      placeholder: '',
      itemKey: 'code_value',
      itemValue: 'code_display',
      required: true,
      error: 'State name is required',
    },
  ],
  BtnText: 'Add Card and Continue',
};

export const PAYMENT_DETAILS_ERRORS: Map = {
  TITLE: 'Could not load payment details',
  MSG: {
    INVALID_TOKEN: DEFAULT_ERRORS.INVALID_TOKEN,
    ERROR: DEFAULT_ERRORS.ERROR,
  },
};
