import styled, { ThemeProvider } from 'styled-components';

import theme, { ButtonKind, TypographyStyle } from '../../theme/theme';
import { ICardDetails } from '../../types/CardTypes';
import {
  convertToTitleCase,
  convertUnderscorestoSpaces,
} from '../../utils/utils';
import Button from '../Button/Button';
import Typography from '../Typography/Typography';

const ContainerDiv = styled.div`
  padding: 1em;
  color: ${(props) => props.theme.color.gray1};
  display: flex;
  vertical-align: middle;
  justify-content: center;
  flex-direction: column;
`;

const HeadingText = styled(Typography)`
  font-weight: bold;
  padding: 1em 0em;
`;

const ButtonStyled = styled(Button)`
  margin: 3em 0em;
`;

export interface ICardDeletedProps {
  handleOkClick: () => void;
  deletedCardDetails: ICardDetails;
}

function PaymentMethodDeleted({
  handleOkClick,
  deletedCardDetails,
}: ICardDeletedProps) {
  const { defaultCard, lastFour, cardBrand } = deletedCardDetails;

  return (
    <ThemeProvider theme={theme}>
      <ContainerDiv>
        <HeadingText
          typographyStyle={TypographyStyle.MajorNormal}
          label="Payment method removed"
        />

        <Typography
          typographyStyle={TypographyStyle.MinorNormal}
          label={`${convertToTitleCase(
            convertUnderscorestoSpaces(cardBrand)
          )} ending in ${lastFour} has been removed.
          `}
        />

        <ButtonStyled
          kind={ButtonKind.Primary}
          label="Ok"
          onClick={handleOkClick}
        />
      </ContainerDiv>
    </ThemeProvider>
  );
}

export default PaymentMethodDeleted;
