import React, { useState } from 'react';
import styled from 'styled-components';
import { ErrorDiv } from '../AccertifyCardInput/AccertifyStyles';

const DivStyled = styled.div`
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
`;

const LabelStyled = styled.label`
  color: #3d3d3d;
  display: block;
  font-family: system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
`;

const SelectStyled = styled.select`
  border: 1px solid #dedede;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 10px 10px 14px 10px;
  width: 100%;
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 95% 50%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
`;

export interface IDropdownProps {
  required?: boolean;
  onChangehandler: (inputIdentifier: any, value: any, isValid: boolean) => void;
  identifier: string;
  label: string;
  labelName: string;
  ariaLabel: string;
  dropdownValues: any[];
  tabIndex?: number;
  itemKey?: string;
  itemValue?: string;
  controlledErrorMsg: string;
}

const DropdownInput: React.FC<IDropdownProps> = (props: IDropdownProps) => {
  const [isInputValid, setIsInputValid] = useState<boolean>(false);
  const [touched, setTouched] = useState<boolean>(false);

  const onSelect = (inputIdentifier: string, value: string) => {
    let isValid = false;
    setIsInputValid(false);
    if (value !== 'Select') {
      isValid = true;
      setIsInputValid(true);
    }
    props.onChangehandler(inputIdentifier, value, isValid);
  };

  return (
    <DivStyled>
      <LabelStyled htmlFor={props.label}>{props.labelName}</LabelStyled>
      <SelectStyled
        name={props.label}
        onChange={(e) => onSelect(props.identifier, e.target.value)}
        onBlur={() => setTouched(true)}
        required={props.required}
      >
        <option key="select">Select</option>
        {props.dropdownValues.map((item) => {
          let key = props.itemKey ? item[props.itemKey] : item;
          let value = props.itemValue ? item[props.itemValue] : item;
          return <option key={key}>{value}</option>;
        })}
      </SelectStyled>
      {touched && !isInputValid && (
        <ErrorDiv>{props.controlledErrorMsg}</ErrorDiv>
      )}
    </DivStyled>
  );
};

export default DropdownInput;
