export function convertToTitleCase(sentence: string): string {
  return sentence
    .trim()
    .toLowerCase()
    .replace(/^(.)|\s+(.)/g, (toReplace) => {
      return toReplace.toUpperCase();
    });
}

export function convertUnderscorestoSpaces(input: string): string {
  return input.replace(/_/g, ' ');
}

export function getExpiryYears() {
  let expiryYears = ['Select'];
  let today = new Date();
  for (var i = 0; i < 10; i++) {
    expiryYears.push(today.getFullYear().toString().substr(2, 2));
    today.setFullYear(today.getFullYear() + 1);
  }
  return expiryYears;
}

export function getExpiryMonths() {
  let expiryMonths = ['Select'];

  for (var i = 1; i <= 12; i++) {
    if (i < 10) {
      expiryMonths.push('0' + i.toString());
    } else {
      expiryMonths.push(i.toString());
    }
  }
  return expiryMonths;
}
