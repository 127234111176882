import { Card, ICardDetails } from '../types/CardTypes';
import { del, get, patch, post, put } from './rest-client';

export function getCustomerCards(): Promise<Card[]> {
  return get(`/card`).then((response) => response.cards);
}

export function getCardDetails(cardId: string): Promise<ICardDetails> {
  return get(`/card/${cardId}`).then((res) => res.card);
}

export function setAsDefaultPaymentMethod(
  cardId: string
): Promise<ICardDetails> {
  return patch(`/card/${cardId}`, { defaultCard: true }).then(
    (res) => res.card
  );
}

export function createPaymentMethod(
  cardInfo: AccertifyCreateCardDto | SquareCreateCardDto
): Promise<ICardDetails> {
  return post(`/card`, cardInfo).then((res) => res.card);
}

export function editPaymentMethod(
  cardId: string,
  cardNonce: string
): Promise<{ card: ICardDetails }> {
  return put(`/card/${cardId}`, { cardNonce });
}

export function removePaymentMethod(cardId: string): Promise<void> {
  return del(`/card/${cardId}`);
}

export interface SquareCreateCardDto {
  cardNonce: string;
  defaultCard: boolean;
}

export interface AccertifyCreateCardDto {
  sessionId: string;
}
