import React from 'react';
import styled from 'styled-components';

import { CardSubText } from '../CardListItem/CardListItem';
import { flagIfExpired } from '../ExpireService/ExpireService';

const FormStyled = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid RGB(230, 231, 232);
`;

const InputStyled = styled.input`
  margin-right: 15px;
  margin-top: 7px;
  cursor: pointer;
`;

const DisabledItemStyled = styled.div`
  opacity: 65%;

  ${CardSubText} {
    opacity: 100%;
  }
`;

const RadioContainerStyled = styled.label`
  padding: 24px 16px;
  border-bottom: 2px solid RGB(230, 231, 232);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  :last-child {
    border-bottom: none;
  }
`;

export interface IRadioSelectFormProps {
  groupName: string;
  selected: string | undefined;
  setSelected: any;
  noCardsOnFile: boolean;
  defaultCardId: string;
}

const RadioSelectForm: React.FC<IRadioSelectFormProps> = ({
  groupName,
  selected,
  setSelected,
  noCardsOnFile,
  defaultCardId,
  ...props
}) => {
  const isDefaultCardId = (key: any) => {
    return typeof key === 'string' && key === defaultCardId;
  };
  return (
    <FormStyled>
      {React.Children.map(props.children, (item, key) => {
        if (React.isValidElement(item)) {
          return (
            <>
              {typeof item?.props?.expDate !== 'undefined' &&
                flagIfExpired(item.props.expDate) && (
                  <RadioContainerStyled>
                    <InputStyled
                      type="radio"
                      name={groupName}
                      disabled
                    ></InputStyled>
                    <DisabledItemStyled>{item}</DisabledItemStyled>
                  </RadioContainerStyled>
                )}
              {(typeof item?.props?.expDate === 'undefined' ||
                !flagIfExpired(item.props.expDate)) && (
                <RadioContainerStyled onClick={() => setSelected(item)}>
                  {!noCardsOnFile && (
                    <InputStyled
                      type="radio"
                      name={groupName}
                      defaultChecked={isDefaultCardId(item?.key)}
                    ></InputStyled>
                  )}
                  <div>{item}</div>
                </RadioContainerStyled>
              )}
            </>
          );
        }
      })}
    </FormStyled>
  );
};

export default RadioSelectForm;
