import React from 'react';
import AddPaymentMethod from '../add-payment-method/AddPaymentMethod';

const AddPaymentMethodFrame: React.FC = () => {
  const onInit = () => {
    window.xprops?.onInitHook();
  };

  const onSuccess = (cardId: string) => {
    window.xprops?.onAddCardSuccessHook(cardId);
  };

  const onFailure = () => {
    window.xprops?.onAddCardFailureHook &&
      window.xprops?.onAddCardFailureHook();
  };

  const onResize = (width: number, height: number) => {
    window.xprops?.onResizeHook && window.xprops?.onResizeHook(width, height);
  };

  return (
    <AddPaymentMethod
      onInit={onInit}
      onSuccess={onSuccess}
      onFailure={onFailure}
      onResize={onResize}
      url={window.xprops?.client_url}
      clientId={window.xprops?.clientId}
      states={window.xprops?.stateArray}
    />
  );
};

export default AddPaymentMethodFrame;
