function parseExpDate(expirationDate: string): Date {
  const expDateArr = (expirationDate + '').split('/');
  const expMonth = Number(expDateArr[0]);
  const expYear = Number(expDateArr[1]);

  return new Date(expYear, expMonth, 0);
}

export function flagIfExpiring(expirationDate: string): boolean {
  let today = new Date();
  const expDate = parseExpDate(expirationDate);
  let expDateThreshold = new Date(expDate);
  expDateThreshold.setDate(expDateThreshold.getDate() - 30);

  if (expDateThreshold <= today && !flagIfExpired(expirationDate)) {
    return true;
  }
  return false;
}

export function flagIfExpired(expirationDate: string | undefined): boolean {
  if (typeof expirationDate === 'undefined') {
    return false;
  }
  const today = new Date();
  const lastDayOfCurrentMonth = new Date(
    today.getFullYear(),
    today.getMonth() + 1,
    0
  );
  const expDate = parseExpDate(expirationDate);

  if (expDate < lastDayOfCurrentMonth) {
    return true;
  }

  return false;
}
