import styled, { ThemeProvider } from 'styled-components';

import theme, { ButtonKind, TypographyStyle } from '../../theme/theme';
import { ICardDetails } from '../../types/CardTypes';
import Button from '../Button/Button';
import CardItem from '../CardItem/CardItem';
import ErrorBanner from '../ErrorBanner/ErrorBanner';
import { flagIfExpired, flagIfExpiring } from '../ExpireService/ExpireService';
import LabelValue from '../LabelValue/LabelValue';
import Typography from '../Typography/Typography';

const ContainerDiv = styled.div`
  padding: 0em 1em;
  color: ${(props) => props.theme.color.gray1};
`;

const SubContainerDiv = styled.div`
  display: flex;
  vertical-align: middle;
  justify-content: center;
  flex-direction: column;
  padding: 1em 0em;
`;

const HeadingText = styled(Typography)`
  font-weight: bold;
`;

const ButtonStyled = styled(Button)`
  margin: 0.5rem 0rem;
`;

const CardSubText = styled(Typography)`
  margin-left: 3rem;
  opacity: 65%;
`;

const CardListItemStyled = styled.div`
  margin: 0.5rem 0rem;
  display: flex;
  flex-direction: column;
`;

const ElementContainerDiv = styled.div`
  display: flex;
  vertical-align: middle;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 0.3rem;
  padding-top: 0.3rem;
`;

const LabelTypography = styled(Typography)`
  font-weight: bold;
`;
export interface ICardDetailsProps {
  cardDetails: ICardDetails;
  handleEditCardClick: (cardId: string) => void;
  handleSetAsDefaultCardClick: () => void;
  disableDefaultPayment: boolean;
  handleDeleteCardClick: () => void;
}

function ViewCardDetails({
  cardDetails,
  handleEditCardClick,
  handleSetAsDefaultCardClick,
  disableDefaultPayment,
  handleDeleteCardClick,
}: ICardDetailsProps) {
  const { id, cardBrand, lastFour, nameOnCard, expDate, defaultCard } =
    cardDetails;

  return (
    <ThemeProvider theme={theme}>
      <ContainerDiv>
        <SubContainerDiv>
          {flagIfExpired(expDate) && (
            <ErrorBanner message={'This card has expired'} />
          )}
          <HeadingText
            typographyStyle={TypographyStyle.MinorNormal}
            label={'Card description'}
          />

          <CardListItemStyled>
            <CardItem
              pageName="cardDetails"
              lastFour={lastFour}
              cardType={cardBrand}
            ></CardItem>
            {defaultCard && (
              <CardSubText
                typographyStyle={TypographyStyle.MinorQuiet}
                label={'Default payment method'}
              />
            )}
          </CardListItemStyled>
          {nameOnCard && (
            <LabelValue label={'Name on card'} value={nameOnCard} />
          )}

          <ElementContainerDiv>
            <LabelTypography
              typographyStyle={TypographyStyle.MinorNormal}
              label={'Expiration date'}
            />
            <Typography
              typographyStyle={TypographyStyle.MinorNormal}
              label={expDate}
              {...(flagIfExpiring(expDate) ? { style: { color: 'red' } } : {})}
            />
          </ElementContainerDiv>
        </SubContainerDiv>

        <SubContainerDiv>
          <ButtonStyled
            kind={ButtonKind.Primary}
            label="Edit"
            onClick={() => handleEditCardClick(id)}
          />

          {!defaultCard && !flagIfExpired(expDate) && (
            <ButtonStyled
              kind={ButtonKind.Secondary}
              label="Set as default payment method"
              onClick={handleSetAsDefaultCardClick}
              disabled={disableDefaultPayment}
            />
          )}

          <ButtonStyled
            kind={ButtonKind.Tertiary}
            label="Remove payment method"
            style={{ color: theme.color.error }}
            onClick={handleDeleteCardClick}
          />
        </SubContainerDiv>
      </ContainerDiv>
    </ThemeProvider>
  );
}

export default ViewCardDetails;
